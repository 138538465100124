import React, { ReactNode } from 'react'

import enUsLocalisation from './translations/en-US'
import fiFiLocalisation from './translations/fi-FI'

export enum MeasurLocale {
  US_ENGLISH = 'en-US',
  FINLAND_FINNISH = 'fi-FI',
}

export const localeValues = [
  MeasurLocale.US_ENGLISH,
  MeasurLocale.FINLAND_FINNISH,
]

export const dateFormats: { [l in MeasurLocale]: string } = {
  [MeasurLocale.US_ENGLISH]: 'd MMM yyyy',
  [MeasurLocale.FINLAND_FINNISH]: 'd.M.yyyy',
}

export const dateTimeFormats: { [l in MeasurLocale]: string } = {
  [MeasurLocale.US_ENGLISH]: 'd MMM yyyy HH:mm',
  [MeasurLocale.FINLAND_FINNISH]: 'd.M.yyyy HH.mm',
}

export const localeLanguage = (locale: MeasurLocale) => {
  const [lang] = locale.split('-')
  if (typeof lang === 'undefined') {
    throw new Error(`Unable to parse locale ${locale}`)
  }
  return lang
}

export const inLocaleNames: { [l in MeasurLocale]: string } = {
  [MeasurLocale.US_ENGLISH]: 'In English',
  [MeasurLocale.FINLAND_FINNISH]: 'suomeksi',
}

export interface Localisation {
  [key: string]: string | Localisation
}

export const uiTexts: { [ml in MeasurLocale]: Localisation } = {
  [MeasurLocale.FINLAND_FINNISH]: fiFiLocalisation,
  [MeasurLocale.US_ENGLISH]: enUsLocalisation,
}

export const interpolate = (
  s: string,
  values: Record<
    string,
    ((s: string | undefined, index: number) => ReactNode) | ReactNode
  >,
) => {
  let i = 0
  const nodes: ReactNode[] = []
  const occurrences: Record<string, number> = {}
  for (const match of s.matchAll(/{{([a-zA-Z_]+)(?:\|([^}]*))?}}/g)) {
    if (typeof match.index === 'undefined') {
      break
    }
    if (match.index > i) {
      nodes.push(s.slice(i, match.index))
    }
    const [, key, param] = match
    if (key && Object.prototype.hasOwnProperty.call(values, key)) {
      let value = values[key]
      if (typeof value === 'function') {
        value = value(param, occurrences[key] ?? 0)
      }
      occurrences[key] = (occurrences[key] ?? 0) + 1
      nodes.push(value)
    } else {
      throw new Error(`a key "${key ?? ''}" was not passed for "${s}"`)
    }
    i = match[0].length + match.index
  }
  const remainder = s.slice(i)
  if (remainder) {
    nodes.push(remainder)
  }
  return nodes
}

type DeriveTranslationKeys<T, Prefix extends string = ''> =
  T extends Record<string, unknown>
    ? {
        [K in keyof T]: K extends string | number
          ? T[K] extends string | number
            ? `${Prefix}${K}`
            : DeriveTranslationKeys<T[K], `${Prefix}${K}.`>
          : never
      }[keyof T]
    : never

export type TranslationKey = DeriveTranslationKeys<typeof enUsLocalisation>

export type LocaleSettings = {
  collator: Intl.Collator
  locale: MeasurLocale
  t: (key: TranslationKey, fallback?: string) => string
  interpolate: typeof interpolate
}

export const LocaleContext = React.createContext<LocaleSettings>({
  collator: Intl.Collator(localeLanguage(MeasurLocale.US_ENGLISH), {
    numeric: true,
    sensitivity: 'base',
  }),
  interpolate: () => {
    throw new Error('interpolation not defined')
  },
  locale: MeasurLocale.US_ENGLISH,
  t: () => {
    throw new Error('translator not defined')
  },
})

export const isHyphenatedLocale = () => {
  const { locale } = React.useContext(LocaleContext)
  return locale === MeasurLocale.FINLAND_FINNISH
}

const formatPrice = (price: number, locale: MeasurLocale) => {
  return price.toLocaleString(locale, { maximumFractionDigits: 2 })
}

export const formatPriceWithCurrency = (
  price: number,
  locale: MeasurLocale,
  currency: string,
) => {
  if (currency === 'EUR') {
    return `${formatPrice(price, locale)} €`
  }
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
  })
  return formatter.format(price)
}
export const formatPriceRange = (
  range: [number, number],
  locale: MeasurLocale,
  currency: string,
) => {
  if (range[0] === range[1]) {
    return formatPriceWithCurrency(range[0], locale, currency)
  }

  // In English, the range is formatted as "£10–20"
  if (['GBP', 'USD'].includes(currency) && locale === MeasurLocale.US_ENGLISH) {
    return `${formatPriceWithCurrency(range[0], locale, currency)}–${formatPrice(
      range[1],
      locale,
    )}`
  }
  // Other languages and currencies are formatted as "10–20 €"
  return `${formatPrice(range[0], locale)}–${formatPriceWithCurrency(
    range[1],
    locale,
    currency,
  )}`
}
